import React, { useState, useEffect } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import logo from '../assets/images/black 2.png'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
const Nav = (props) => {


    return (
        <>
        <div className=' space'>
<img alt='' src={logo} className='app___img'/>  
{
    props.show?
    <>
    <div className=''>
<p>Scan BarCode</p>   
<QrCodeScannerIcon/>
</div>
</>
:
''
} 
</div>       
        </>
    )
}

export default Nav
