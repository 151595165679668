import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ProtectPage from './Component/ProtectPage'
import { login, logout } from './Redux/slices/UserSlice';
import Login from './Component/Login/Login'
import { useDispatch } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProductDetails from './Component/ProductDetails.js/ProductDetails';
import logo from '../src/assets/images/black 2.png'
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const usertoken = localStorage.getItem('token');
    if (usertoken) {
      try {
        dispatch(logout());
      } catch (error) {
        console.error('Error parsing token:', error);
      }
    }

  }, [dispatch]);

  return (
    <div className="App">
       <BrowserRouter>
 
        <Routes>
        <Route element={<ProtectPage />}>
        <Route path="/" element={<ProductDetails />} />

        </Route>
        <Route path="/login" element={<Login />} />
        </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
