import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import style from "../../Styles/login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../Redux/slices/UserSlice";
import axios from "axios";
import Environment from "../../config";
import { render } from "react-dom";
import Keyboard from "react-simple-keyboard";

import "react-simple-keyboard/build/css/index.css";

const Login = () => {
  const navigate = useNavigate();
  const [userCode, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [codeOrpassword, setCodeOrPassword] = useState('');
  const [name, setName] = useState("");
  const { loading, error } = useSelector((state) => state.user);
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [layoutName, setLayoutName] = useState("default");
  const keyboard = useRef();
  const onChange = (input) => {
    setCode(input);
    console.log("Input changed", input);
  };

  const onKeyPress = (button) => {
    console.log("Button pressed", button);
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleShift = () => {
    setLayoutName((prevLayout) => (prevLayout === "default" ? "shift" : "default"));
  };

  const onChangeInput = (event) => {
    const input = event.target.value;
    setCode(input);
    keyboard.current.setInput(input);
  };

  /////////////////////////

  const [layoutNamePass, setLayoutNamePass] = useState("default");
  const keyboardPass = useRef();
  const onChangePass = (input) => {
    setPassword(input);
    console.log("Input changed", input);
  };

  const onKeyPressPass = (button) => {
    console.log("Button pressed", button);
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleShiftPass = () => {
    setLayoutNamePass((prevLayout) => (prevLayout === "default" ? "shift" : "default"));
  };

  const onChangeInputPass = (event) => {
    const input = event.target.value;
    setPassword(input);
    keyboardPass.current.setInput(input);
  };
  function login(e) {
    e.preventDefault();
    const reqData = {
      userCode,
      userPassword: encodeURIComponent(password),
    };
    toast.loading("...loading");
    dispatch(loginUser(reqData)).then((result) => {
      console.log(result.payload, "ggg");
      if (result.payload) {
        setCode("");
        setPassword("");
        navigate("/");

        localStorage.setItem("userCode", userCode);
      } else {
        toast.error(error);
      }
    });
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      login(e);
    }
  };
  return (
    <>
      <Container>
        <section className={`${style.loginpage}`}>
          <Row>
            <Col>
              <h3 className={`${style.signin}`}>Sign in</h3>
              <div className={`${style.signin__form}`}>
                <Form.Group className="mb-3 " controlId="email">
                  <Form.Label className={`${style.label}`}>
                    Enter your code{" "}
                  </Form.Label>
                  <Form.Control
                    name="userCode"
                    type="text"
                    autoComplete="off"
                    placeholder=""
                    className={`${style.full}`}
                    value={userCode}
                    onChange={onChangeInput}
                    onClick={()=>setCodeOrPassword('codee')}
                  />
                </Form.Group>

                <Form.Group className="mb-3 mt-4 " controlId="password">
                  <Form.Label className={`${style.label}`}>Password</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    autoComplete="off"
                    placeholder=""
                    className={`${style.full}`}
                    value={password}
                    onChange={onChangeInputPass}
                    onClick={()=>setCodeOrPassword('passe')}
                    ref={formRef}
                    onKeyPress={handleKeyPress}
                  />
                </Form.Group>
                <button
                  className={style.log__btn}
                  type="button"
                  onClick={login}
                >
                  Sign In
                </button>

          
              </div>
            </Col>
          </Row>
          
          <ToastContainer />
        </section>
        {
            codeOrpassword=='codee'?
<Keyboard
        keyboardRef={(r) => (keyboard.current = r)}
        layoutName={layoutName}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      :''
        }
        {
            codeOrpassword=='passe'?
            <Keyboard
        keyboardRef={(r) => (keyboardPass.current = r)}
        layoutName={layoutNamePass}
        onChange={onChangePass}
        onKeyPress={onKeyPressPass}
      />
      :
      ''
        }
      </Container>
    </>
  );
};

export default Login;
/* import React, { useEffect, useRef, useState } from "react";
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Col, Row, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../Redux/slices/UserSlice';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import style from '../../Styles/login.module.css';

const Login = () => {
    const navigate = useNavigate();
    const [layoutName, setLayoutName] = useState("default");
    const [userCode, setCode] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const keyboardRef = useRef();
    const { error } = useSelector((state) => state.user);

    const onChange = (input) => {
        if (document.activeElement.name === 'userCode') {
            setCode(input);
        } else if (document.activeElement.name === 'password') {
            setPassword(input);
        }
        console.log("Input changed", input);
    };

    const onKeyPress = (button) => {
        console.log("Button pressed", button);
        if (button === "{shift}" || button === "{lock}") handleShift();
    };

    const handleShift = () => {
        setLayoutName((prevLayout) => 
            prevLayout === "default" ? "shift" : "default"
        );
    };

    const onChangeInput = (event) => {
        const inputValue = event.target.value;
        if (event.target.name === 'userCode') {
            setCode(inputValue);
        } else {
            setPassword(inputValue);
        }
        keyboardRef.current.setInput(inputValue);
    };

    const login = (e) => {
        e.preventDefault();
        const reqData = { userCode, userPassword: encodeURIComponent(password) };
        toast.loading('...loading');
        dispatch(loginUser(reqData)).then((result) => {
            if (result.payload) {
                setCode('');
                setPassword('');
                navigate('/');
                localStorage.setItem('userCode', userCode);
            } else {
                toast.error(error);
            }
        });
    };

    useEffect(() => {
        // Set initial input value on keyboard mount
        if (keyboardRef.current) {
            keyboardRef.current.setInput(userCode || password);
        }
    }, [userCode, password]);

    return (
        <>
            <Container>
                <section className={`${style.loginpage}`}>
                    <Row>
                        <Col>
                            <h3 className={`${style.signin}`}>Sign in</h3>
                            <div className={`${style.signin__form}`}>
                                <Form.Group className="mb-3" controlId="userCode">
                                    <Form.Label>Enter your code</Form.Label>
                                    <Form.Control
                                        name="userCode"
                                        type='text'
                                        value={userCode}
                                        onChange={onChangeInput}
                                        placeholder="Tap on the virtual keyboard to start"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 mt-4" controlId="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        name="password"
                                        type='password'
                                        value={password}
                                        onChange={onChangeInput}
                                        placeholder="Tap on the virtual keyboard to start"
                                    />
                                </Form.Group>
                                <button className={style.log__btn} type='button' onClick={login}>Sign In</button>
                            </div>
                        </Col>
                    </Row>
                    <ToastContainer />
                </section>
                <div className="keyboard" ref={keyboardRef} style={{ marginTop: '20px' }} />
                <Keyboard
                    keyboardRef={(r) => (keyboardRef.current = r)}
                    layoutName={layoutName}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                />
            </Container>
        </>
    );
};

export default Login; */
