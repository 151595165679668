import React, { useEffect, useRef, useState } from 'react'
import BarcodeReader from 'react-barcode-reader';
import Environment from '../../config';
import { useSelector } from "react-redux";
import styles from '../../Styles/productdeatils.module.css'
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import barcodeee from '../../assets/images/WhatsApp_Image_2024-10-15_at_2.01.48_PM-removebg-preview.png';
import noImg from '../../assets/images/no image.png'
import Nav from '../../Layout/Nav';
import Accordion from 'react-bootstrap/Accordion';

const ProductDetails = () => {
  const { token } = useSelector((state) => state.user);
  const [barcode, setBarcode] = useState('');
  const [product, setProduct] = useState(null);
  const [error, setError] = useState('');
  const [colorImage, setColorImage] = useState([]);
  const [selectColor, setSelectColor] = useState({});
  const [firstSize, setFirstSize] = useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [productBranch, setProductBranch] = useState([]);
  const [loading, setIsLoading] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
useEffect(() => {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (barcode.trim() === '') return; // Prevent fetching if no barcode entered

      setIsLoading(true);
      fetchProductDetails(barcode);
      setBarcode(''); // Clear barcode input
      setExpanded(false); // Close accordion on Enter key
    } else {
      setBarcode((prev) => prev + e.key); // Append key to barcode
      setExpanded(false); // Close accordion while typing
    }
  };

  window.addEventListener('keypress', handleKeyPress);
  return () => {
    window.removeEventListener('keypress', handleKeyPress);
  };
}, [barcode]);
  const fetchProductDetails = async (barcode) => {
    try {
      const response = await fetch(`${Environment.baseURL}/api/${Environment.project}/ProductInquiryStoreAssestante?barCode=${barcode}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json', 
        }
      });
  
      if (!response.ok) {
        setIsLoading(false)
        throw new Error('Product not found');

      }
  
      const productData = await response.json();
      setProduct(productData);
      setColorImage(productData?.colors)
      setSelectColor( productData?.colors?.find(
        (item) => item.itemColor === productData.defaultColor
    ))
    const sizess=productData?.colors?.find(
      (item) => item.itemColor === productData.defaultColor
  )
    setFirstSize(sizess?.sizes?.find(
      (item) => item.itemSize == productData.defaultSize

  ))
  setIsLoading(false)
      setError('');
    } catch (error) {
      setError(error.message);
      setProduct(null);
    }
  };
  const thumbnailRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);

  const plusSlides = (n) => {
    setSlideIndex((prevIndex) => {
      let newIndex = prevIndex + n;
      if (newIndex >= selectColor?.productPic.length) return 0;
      if (newIndex < 0) return selectColor?.productPic.length - 1;
      return newIndex;
    });
  };

  const currentSlide = (n) => {
    setSlideIndex(n);
    const thumbnail = thumbnailRef.current;
    if (thumbnail) {
      const selectedThumbnail = thumbnail.children[n];
      if (selectedThumbnail) {
        selectedThumbnail.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };
  
  const handleWheel = (event) => {
    if (thumbnailRef.current && thumbnailRef.current.contains(event.target)) {
      event.preventDefault();
      thumbnailRef.current.scrollBy({
        top: event.deltaY,
        behavior: 'smooth',
      });
    }
  };

  React.useEffect(() => {
    const thumbnailContainer = thumbnailRef.current;
    if (thumbnailContainer) {
      thumbnailContainer.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (thumbnailContainer) {
        thumbnailContainer.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);
  const uniqueColors = {};

  if (Array.isArray(colorImage)) {
    for (const item of colorImage) {
      const colorKey = `${item.itemColor}-${item.colorHex}`;
      if (!uniqueColors[colorKey]) {
        uniqueColors[colorKey] = {
          itemColor: item.itemColor,
          colorName: item.colorName,
          colorHex: item.colorHex,
          sizes: [],
          productImages: item.productImages,
        };
      }
     /*  uniqueColors[colorKey].sizes.push({
        sizeName: item.sizeName,
        itemSize: item.itemSize,
        qty: item.quantity,
      }); */
    }

    for (const key in uniqueColors) {
      if (uniqueColors.hasOwnProperty(key)) {
        uniqueColors[key].sizes.sort((a, b) => a.itemSize - b.itemSize);
      }
    }
  }
  const result = Object.values(uniqueColors);
  const handleSizeSelection = (size) => {
    setFirstSize(size);
  };

  const handleColorSelection = (color) => {
    setSelectColor(color);
    setFirstSize(color?.sizes[0]);
  };
console.log(colorImage?.itemColor)
  useEffect(() => {
    if(product?.itemCode&&selectColor?.itemColor&&firstSize?.itemSize){
      axios
      .get(`${Environment.baseURL}/api/${Environment.project}/ProductInquiryStoreAssestante/getAvailableBranchs?itemCode=${product.itemCode}&color=${selectColor.itemColor}&size=${firstSize.itemSize}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json', 
        }
      })
      .then((response) => {
        setProductBranch(response.data)
      })
      .catch((err) => {
        console.log(err);
      });
    }
   
  }, [product?.itemCode,selectColor?.itemColor,firstSize?.itemSize]);
  console.log(productBranch)

  if (loading) return( <>
   <div className="loader"></div>

    </>
    )
  return (
    <>
    {
      product?
      <Nav show={true}/>
      :
      <Nav show={false}/>
    }
   
    <div className={`${styles.code__product}`} style={{ width: '100%' ,height:'100%'}}>
      {
        product?
        ''
        :
        <div className={`${styles.proooo}`}>
        <img alt='' src={barcodeee}/>
        {error ?
         <p style={{ color: 'red' }}>{error} Scan again or Write BarCode to Show Product</p>
        :
        <p>Scan BarCode</p>

        }

       </div>
      }
      
<Container className='mt-5'>
      {product && 
       <Row >
    
       <Col md={6} className={`${styles.row__details}`}>
         <h2>{product.itemName}</h2>
       
         <div className={`${styles.price__body}`}>
           {product.defaultDealPrice == 0 ? (
             <p> EGP {product.defaultPrice}</p>
           ) : (
             <div className={`${styles.update__price}`}>
               <p>EGP {product.defaultDealPrice}</p>
               <del className={`${styles.price__del}`}>
                 EGP {product.defaultPrice}
               </del>
             </div>
           )}
         </div>
         <p className={`${styles.choose__color__para}`}></p>
         <div className={`${styles.choose__color}`}>
           {colorImage.map((color) => (
             <div
               key={color.itemColor}
               style={{
                 backgroundColor: `#${color.colorHex}`,
                 cursor: "pointer",
                 width: "30px",
                 height: "30px",
               }}
               className={
                 selectColor?.itemColor == color.itemColor
                   ? `${styles.selectcolorhex} boxshadow mb-2`
                   : `mb-2 boxshadow`
               }
               onClick={() => handleColorSelection(color)}
             ></div>
           ))}
         </div>
         <p className={`${styles.choose__color__para}`}></p>
         <div className={`${styles.size__body}`}>
           {selectColor?.sizes?.map((siz) => (
             <p
               key={siz.itemSize}
               className={
                 firstSize?.itemSize == siz?.itemSize
                   ? `${styles.selectsize}`
                   : `${styles.size}`
               }
               onClick={() => handleSizeSelection(siz)}
             >
               {siz.sizeName}
             </p>
           ))}
         </div>
         <div className={`${styles.qty__body}`}>
         <p className={`${styles.choose__color__para}`}>الكمية : <span>{firstSize?.qty}</span></p>
        
         </div>
         <Accordion className={`${styles.hr}`}>
      <Accordion.Item eventKey="0">
        <Accordion.Header> الفروع المتاح بها الصنف</Accordion.Header>
        <Accordion.Body>
        {productBranch.length ==0?
           <p className={`${styles.code__product}`} style={{ color: 'red',textAlign:'center',fontWeight:'bold'}}>لا يوجد فروع متاح بها الصنف</p>
           :

            productBranch?.map(branch=>(
              <>
              <div key={branch.branchCode} className={`${styles.code__product} ${styles.modal__code}`}>
              <p> {branch.branchName} </p> 
              <p>الكمية المتاحة بالفرع : <span>{branch.availableQty}</span></p>
              </div>
              <hr/>
              </>
            ))
           }
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
     {/*     <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={`${styles.hr}`}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '93%', flexShrink: 0 }} className={`${styles.code__product}`}>
          الفروع المتاح بها الصنف
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           {productBranch.length ==0?
           <p className={`${styles.code__product}`} style={{ color: 'red',textAlign:'center',fontWeight:'bold'}}>لا يوجد فروع متاح بها الصنف</p>
           :

            productBranch?.map(branch=>(
              <>
              <div key={branch.branchCode} className={`${styles.code__product} ${styles.modal__code}`}>
              <p> {branch.branchName} </p> 
              <p>الكمية المتاحة بالفرع : <span>{branch.availableQty}</span></p>
              </div>
              <hr/>
              </>
            ))
           }
          </Typography>
        </AccordionDetails>
      </Accordion> */}
       </Col>
       <Col  md={6}>
         {selectColor?.productImages?.length!=0 ? (
          <div className={`${styles.select__images}`}>
           <div className={`${styles.mySwiper2}`}>
             {selectColor?.productImages?.map((src, index) => (
               <div className={`${styles.mySlides} ${index === slideIndex ? styles.active : ''}`} key={index}>
                 <img src={src} style={{ width: '100%' }} alt={`Slide ${index + 1}`} />
               </div>
             ))}                 
           </div>
           <div className={`${styles.mySwiper}`} ref={thumbnailRef}>
             {selectColor?.productImages?.map((src, index) => (
               <div className={`${styles.column}`} key={index}>
                 <img
                   className={` ${styles.demo}${index === slideIndex ? styles.active : ''}`}
                   src={src}
                   style={{ width: '100%' }}
                   onClick={() => currentSlide(index)}
                   alt={`Thumbnail ${index + 1}`}
                 />
               </div>
             ))}
           </div>
           
           </div> 
         ) : (
           <img alt='' src={noImg} style={{ width: '100%' ,height:'90%'}}/>
         )}

       </Col>
     </Row>
      }
      </Container>
    </div>
    </>
  )
}

export default ProductDetails